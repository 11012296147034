import { Component, OnInit } from '@angular/core';
import { ID_CLIENTE, ID_USUARIO } from 'app/constantes/variaveis';
import { usuarioProvider } from 'services/usuario.provider';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
  {path: 'cadastro-empresa', title: 'Cadastro de Empresas',   icon: '', class: ''},
  {path: 'agenda-prazos', title: 'Agenda de Prazos',   icon: '', class: ''},
  {path: 'parametro-prazos', title: 'Parâmetros de Prazos',   icon: '', class: ''},
  {path: 'parametros-cadastrais-clientes', title: 'Parâmetros Cadastrais',   icon: '', class: ''},
  {path: 'parametro-colidencia', title: 'Parâmetro de Colidência',   icon: '', class: ''},
  {path: 'processos', title: 'Processo de Marca',   icon: '', class: ''},
  {path: 'pesquisa-marca', title: 'Pesquisa de Marca',   icon: '', class: ''},
  {path: 'selecao-colidencia-marca', title: 'Seleção de Colidência',   icon: '', class: ''},
  {path: 'cartas', title: 'Geração de Correspondência de Colidência de Marcas',   icon: '', class: ''},
  {path: 'relatorio', title: 'Relatórios de Colidência',   icon: '', class: ''},
  {path: 'selecao-colidencia-voto', title: 'Colidência Selecionada para Voto',   icon: '', class: ''},
  {path: 'guia-recolhimento/marca', title: 'Guia de Recolhimento - Marca',   icon: '', class: ''},
  {path: 'guia-recolhimento/patente', title: 'Guia de Recolhimento - Patente',   icon: '', class: ''},
  {path: 'guia-recolhimento/desenho-industrial', title: 'Guia de Recolhimento - Desenho Industrial',   icon: '', class: ''},
  {path: 'guia-recolhimento/configuracao', title: 'Configuração',   icon: '', class: ''},
  {path: 'selecao-colidencia-figurativa-marca', title: 'Seleção de Colidência Figurativa',   icon: '', class: ''},
  {path: 'selecao-colidencia-voto-figurativo', title: 'Colidência Selecionada para Voto Figurativo',   icon: '', class: ''},
  {path: 'patentes/processo', title: 'Processo de Patente',   icon: '', class: ''},
  {path: 'patentes/buca-processos', title: 'Pesquisa de Patentes',   icon: '', class: ''},
  {path: 'patentes/agenda-prazos', title: 'Agenda de Prazos - Patentes',   icon: '', class: ''},
  {path: 'patentes/agenda-anuidades', title: 'Agenda de Anuidades - Patentes',   icon: '', class: ''},
  {path: 'importacao-processo-patente', title: 'Importação de Processos em Lote - Patentes', icon: '', class: ''},
  {path: 'pesquisa-processo-rpi', title: 'Pesquisa de Processos em Lote - Patentes', icon: '', class: ''},
  {path: 'desenhos/processo', title: 'Desenho Industrial', icon: '', class: ''},
  {path: 'marcas/pesquisa-marca-cliente', title:'Pesquisa de Marcas', icon:'', class: ''},
  {path: 'marcas/processo-marcas', title:'Processo de Marca', icon:'', class: ''},
  {path: 'pesquisa-nome-empresarial', title:'Pesquisa de Nome Empresarial', icon:'', class: ''}
];


@Component({
  selector: 'app-sistema-sidebar',
  templateUrl: './sistema-sidebar.component.html',
  styleUrls: ['./sistema-sidebar.component.css']
})


export class SistemaSidebarComponent implements OnInit {
  menuItems_marcas: any[];
  menuItems_parametros: any[];
  menuItems_colidencias: any[];
  admistrador:boolean = false
  modulos:Array<number> = [];
  ROUTES: RouteInfo[] = []

  constructor(
    private service: usuarioProvider
  ) { }

  ngOnInit() {
    this.admistrador = localStorage.getItem('adm') != null ? true : false;

    this.service.getModulosLiberados(localStorage.getItem("idUsuario").toString(),localStorage.getItem("idCliente").toString()).subscribe(res => {
      this.modulos = res;     
      console.log(res)     
    },err =>{
    })
 
   
    // let ROUTES_PARAMETROS: RouteInfo[] = []
    // let ROUTES_COLIDENCIAS: RouteInfo[] = []
    // if(localStorage.getItem('adm') == "true")
    // {
      ROUTES.push({path: 'cadastro-usuario', title: 'Cadastro de Usuários',   icon: '', class: ''});
    // }else{
    //   ROUTES.push({path: 'cadastro-empresa', title: 'Cadastro de Empresas',   icon: '', class: ''});
    //   ROUTES_PARAMETROS.push({path: 'parametro-prazos', title: 'Parâmetros de Prazos',   icon: '', class: ''});
    //   ROUTES.push({path: 'agenda-prazos', title: 'Agenda de Prazos',   icon: '', class: ''});      
    //   //ROUTES.push({path: 'agenda-prazos', title: 'Agenda de Prazos',   icon: '', class: ''});  
    //   ROUTES_PARAMETROS.push({path: 'parametros-cadastrais-clientes', title: 'Parâmetros Cadastrais',   icon: '', class: ''}); 
    //   ROUTES_PARAMETROS.push({path: 'parametro-colidencia', title: 'Parâmetro de Colidência',   icon: '', class: ''});       
    //   //ROUTES.push({path: 'processos', title: 'Processo de Marca',   icon: '', class: ''});  
    //   ROUTES.push({path: 'pesquisa-marca', title: 'Pesquisa de Marca',   icon: '', class: ''});
    //   ROUTES_COLIDENCIAS.push({path: 'selecao-colidencia-marca', title: 'Seleção de Colidência',   icon: '', class: ''});       
    //   ROUTES_COLIDENCIAS.push({path: 'selecao-colidencia-voto', title: 'Colidência Selecionada para Voto',   icon: '', class: ''});  
    // }

    // this.menuItems_marcas = ROUTES_MARCAS.filter(menuItem => menuItem);
    // this.menuItems_parametros = ROUTES_PARAMETROS.filter(menuItem => menuItem);
    // this.menuItems_colidencias = ROUTES_COLIDENCIAS.filter(menuItem => menuItem);
    
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
