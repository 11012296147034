// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  // BASE_API_URL:'http://localhost:22867/api',
  // BASE_MARCAS_API_ULR:'https://localhost:44397/api',
  // BASE_URL: 'http://localhost:4200',
  // BASE_GUIA_API:'https://hcip.com.br/GuiaRecolhimento/api',

  BASE_API_URL: 'https://hcip.com.br/projetos/api',
  BASE_MARCAS_API_ULR: 'https://hcip.com.br/marcas/api',
  BASE_URL: 'https://hcip.com.br/',
  BASE_GUIA_API: 'https://hcip.com.br/GuiaRecolhimento/api',
  BASE_CASA_DADOS_API: 'https://hcip.com.br/CasaDosDados/api',
  production: false
};